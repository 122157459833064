.contact-container {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    column-gap: 6rem;
    padding-bottom: 3rem;
  }
  
  .contact-title {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
  
  .contact-info {
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 300px;
  }
  
  .contact-card {
    background-color: #F9F9F8;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 0.75rem;
  }
  
  .contact-card-icon {
    font-size: 2rem;
    color: #57504d;
    margin-bottom: 0.25rem;
  }
  
  .contact-card-title,
  .contact-card-data {
    font-size: 1rem;
  }
  
  .contact-card_title {
    font-weight: 500;
  }
  
  .contact-button {
    color: #57504d;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
  }
  
  .contact-card-data {
    display: block;
    margin-bottom: 0.75rem;
  }
  
  .contact-button-icon {
    font-size: 1rem;
    transition: 0.3s;
  }
  
  .contact-button:hover .contact-button-icon {
    transform: translate(0.3rem);
  }
  
  .contact-form {
    width: 360px;
  }
  
  .contact-form-div {
    position: relative;
    margin-bottom: 2rem;
    height: 4rem;
  }
  
  .contact-form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: none;
    color: #57504d;
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
  }
  
  .contact-form-tag {
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    font-size: 0.8rem;
    padding: 0.25rem;
    background-color: #F9F9F8;
    z-index: 10;
  }
  
  .contact-form-area {
    height: 11rem;
  }
  
  .contact-form-area textarea {
    resize: none;
  }
  
  /* For large devices */
  @media screen and (max-width: 992px) {
      .contact-container {
          column-gap: 3rem;
      }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
      .contact-container {
          grid-template-columns: 1fr;
          row-gap: 3rem;
      }
  
      .contact-info {
          justify-content: center;
      }
  
      .contact-form {
          margin: 0 auto;
      }
  }
  
  @media screen and (max-width: 576px) {
      .contact-info {
          grid-template-columns: 1fr;
      }
  
      .contact-form {
          width: 100%;
      }
  }
  