.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #f2f3f2;
}

.nav {
  height: 4.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 1rem;
}

.nav-logo,
.nav-toggle {
  color: #57504d;
  font-weight: 500;
}

.nav-list {
  display: flex;
  column-gap: 2rem;
}

.nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  color: #57504d;
  font-weight: 100;
  transition: 0.3s;
}

.nav-icon,
.nav-close,
.nav-toggle {
  display: none;
}

.active-link,
.nav-link:hover {
  color: black;
  font-weight: bold;
}

.scroll-navbar {
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
    .navbar {
        top: 0;
        bottom: initial;
    }

    .nav {
        height: 4.5rem;
    }

    .nav-menu {
        position: fixed;
        top: -100%;
        left: 0;
        width: 100%;
        background-color: #f2f3f2;
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: 0.3s;
    }

    .show-menu {
        top: 0;
    }

    .nav-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }

    .nav-icon {
        font-size: 1.2rem;
    }

    .nav-close {
        position: absolute;
        right: 1.3rem;
        top: 0.5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: #57504d;
    }

    .nav-close:hover {
        color: black;
    }

    .nav-toggle {
        width: 24px;
        height: 24px;
        top: 1.2rem;
        cursor: pointer;
    }

    .nav-icon,
    .nav-close,
    .nav-toggle {
        display: block;
    }
}

@media screen and (max-width: 350px) {
    .nav-menu {
        padding: 2rem 0.25rem 4rem;
    }

    .nav-list {
        column-gap: 0;
    }
}