.project-sections {
  grid-template-columns: repeat(3, 350px);
  justify-content: center;
  column-gap: 1.8rem;
}

.project-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.project-button {
  font-size: 1.25rem;
  font-weight: 500;
  color: #444444;
  margin: 0 1rem;
  cursor: pointer;
}

.project-content {
    display: none;
}

.project-content-active {
    display: block;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 1.5rem;
    background-color: #f9f9f8;
    padding: 2.5rem 2rem 2rem 2.5rem;
}

.project-image {
  width: 100%;
}

.project-icon {
  display: block;
  font-size: 2rem;
  color: #444444;
  margin-bottom: 1rem;
}

.project-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.project-button {
  color: #393433;
  font-size: 1rem;
  font-weight: 300;
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  cursor: pointer;
}

.project-button:hover {
    color: black;
    font-weight: bold;
}

.project-tab-button {
    color: #cdcdcd;
    font-size: 1.25rem;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    margin: 0 1rem;
    cursor: pointer;
}

.project-tab-active, .project-tab-button:hover {
    color: #393433;
}

.project-button-icon {
  font-size: 1rem;
  transition: 0.3s;
}

.project-button:hover .project-button-icon {
  transform: translateX(0.4rem);
}

.project-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.project-modal-content {
  width: 500px;
  position: relative;
  background-color: #f9f9f8;
  padding: 4.5rem 2.5rem 2.5rem;
  border-radius: 1.5rem;
}

.project-modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: #444444;
  cursor: pointer;
}

.project-modal-title,
.project-modal-description {
  text-align: center;
}

.project-modal-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.project-modal-description {
  font-size: 0.9rem;
  padding: 0 3.5rem;
  margin-bottom: 2rem;
}

.project-modal-projects {
  row-gap: 0.75rem;
}

.project-modal-project {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin-bottom: 1rem;
}

.rpoject-modal-icon {
  color: #444444;
  font-size: 1.1rem;
}

.project-modal-info {
  font-size: 0.9rem;
}

.active-modal {
  opacity: 1;
  visibility: visible;
}

/* For large devices */
@media screen and (max-width: 1199px) {
  .project-sections {
    grid-template-columns: repeat(2, 1fr);
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* For medium devices */
@media screen and (max-width: 850px) {
  .project-sections {
    grid-template-columns: repeat(2, 1fr);
  }

  .project-content {
    padding: 3.5rem 1.25rem 1.25rem 1.5rem;
  }

  .project-icon {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 650px) {
  .project-modal-content {
    padding: 4.5rem 1.5rem 2.5rem;
  }

  .project-modal-description {
    padding: 0;
  }

  .project-sections {
    grid-template-columns: repeat(1, 1fr);
  }
}