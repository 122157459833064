.footer {
    background-color: #f2f3f2;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-container {
    padding: 2rem 0 6rem;
}

.footer-title,
.footer-link {
    color: #393433;
}

.footer-title {
    text-align: center;
    margin-bottom: 2rem;
}

.footer-link:hover {
    color: black;
    font-weight: bold;
}

.footer-list {
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;
}

.footer-social {
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;
    margin-top: 1rem;
}

.footer-social-link {
    background-color: #f2f3f2;
    color: #57504d;
    padding: 0.4rem;
    display: inline-flex;
}

.footer-social-link:hover {
    color: black;
}

.footer-copy {
    display: block;
    margin-top: 4.5rem;
    color: darkgray;
    text-align: center;
    font-size: 0.9rem;
}
