.home-container {
  height: 100vh;
  row-gap: 7rem;
}

.home-content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 5.5rem;
  column-gap: 2rem;
  align-items: center;
}

.home-social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home-social-icon {
  margin-left: 5rem;
  color: #444444;
}

.home-social-icon:hover {
  color: black;
}

.home-title {
  font-size: 3.5rem;
  margin-bottom: 0.25rem;
}

.home-subtitle {
  position: relative;
  font-size: 1.25rem;
  padding-left: 5.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.home-subtitle::before {
  content: "";
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: black;
  left: 0;
  top: 1rem;
}

.home-description {
  max-width: 400px;
  margin-bottom: 3rem;
}

.home-img {
  background: url(../../assets/profile.png);
  filter: grayscale(90%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
  order: 1;
  justify-content: center;
  width: 300px;
  height: 300px;
  animation: profile__animate 8s ease-in-out infinite 1s;
}

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

.home-scroll {
  margin-left: 9.25rem;
}

.wheel {
  animation: scroll 2s ease infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(3.5rem);
  }
}

.home-scroll-arrow {
  animation: scroll__arrow 2s ease infinite;
}

@keyframes scroll__arrow {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(1rem);
  }
}

.home-scroll-name {
  color: #57504d;
  font-weight: 500;
  margin-right: 0.25rem;
  margin-left: 2px;
}

.home-scroll-arrow {
  font-size: 1.25rem;
  color: #57504d;
}

@media screen and (max-width: 992px) {
  .home-content {
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1.25rem;
  }

  .home-social-icon {
    margin-left: 0;
  }

  .home-subtitle {
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
  }

  .home-subtitle::before {
    width: 42px;
    top: 0.8rem;
  }

  .home-description {
    max-width: initial;
    margin-bottom: var(--mb-2-5);
  }

  .home-img {
    width: 250px;
    height: 250px;
    box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
  }

  .home-scroll {
    margin-left: 7.5rem;
  }
}

@media screen and (max-width: 768px) {
  .home-content {
    grid-template-columns: 0.5fr 3fr;
    padding-top: 3 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  .home-title {
    font-size: 2.6rem;
  }

  .home-img {
    order: initial;
    justify-self: initial;
  }

  .home-data {
    grid-column: 1/3;
  }

  .home-subtitle {
    margin-bottom: 2rem;
  }

  .home-img {
    box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
    width: 200px;
    height: 200px;
  }

  .home-scroll {
    display: none;
  }
}

@media screen and (max-width: 350px) {
  .home-img {
    width: 180px;
    height: 180px;
  }

  .home-title {
    font-size: 2.2rem;
  }
}
